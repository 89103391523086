






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import paymentAccountStatuses from "@/constants/paymentAccountStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý Tài khoản ngân hàng",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            cardNumber: {
              text: "Số thẻ",
              sortable: true,
            },
            bankName: {
              text: "Tên ngân hàng",
              sortable: true,
            },
            status: {
              text: "Trạng thái",
              options: {
                label: true,
                transform(value) {
                  return paymentAccountStatuses[value];
                },
              },
            },
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            endTime: {
              text: "Ngày kết thúc",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            usageMethod: {
              text: "Cách thức sử dụng",
            },
            description: {
              text: "Mô tả",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["kt"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "paymentPartnerId",
              operator: "equal_to",
              value: this.context().$route.params.paymentPartnerId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("paymentAccounts", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.paymentPartnerId = dataTable.context().$route.params.paymentPartnerId;
              return await coreApiClient.call("paymentAccounts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "paymentAccounts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("paymentAccounts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              cardNunber: {
                attrs: {
                  label: "Số thẻ",
                  required: true,
                },
              },
              bankName: {
                attrs: {
                  label: "Tên ngân hàng",
                  required: true,
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(paymentAccountStatuses),
                },
                ext: {
                  defaultValue: "hd",
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày bắt đầu",
                },
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày kết thúc",
                },
              },
              usageMethod: {
                attrs: {
                  label: "Cách thức sử dụng",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: [
                  "name",
                  "cardNumber",
                  "bankName",
                  "status",
                  "usageMethod",
                  "description",
                  "startTime",
                  "endTime",
                ],
              },
            },
            editForm: {
              content: {
                fieldNames: [
                  "name",
                  "cardNumber",
                  "bankName",
                  "status",
                  "usageMethod",
                  "description",
                  "startTime",
                  "endTime",
                ],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
